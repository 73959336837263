* {
    box-sizing: border-box;
}

.scrollbar * {
    scrollbar-width: thin;
}
.scrollbar * ::-webkit-scrollbar {
    background-color: transparent;
    height: 0.5rem;
}
.scrollbar * ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

@media (min-width: 640px) {
    .scrollbar * ::-webkit-scrollbar {
        height: 0.8rem;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

/* Components */
.react-math-keyboard-input-container {
    font-size: 1rem;
    box-sizing: border-box;
    overflow: hidden;
}
/* @media (min-width: 768px) {
  .react-math-keyboard-input-container {
    font-size: 1.1rem;
  }
}
@media (min-width: 1440px) {
  .react-math-keyboard-input-container {
    font-size: 1.4rem;
  }
} */

.react-math-keyboard-input {
    flex-grow: 1;
    /* width: 100%; */
    /* border-radius: 4px; */
    /* border-color: #fff; */
    align-items: center !important;
    display: flex !important;
    scroll-margin-top: 24px;
    box-sizing: border-box;
    overflow: auto;
    /* overflow: hidden; */
    min-width: 4em;
    /* margin: 2px; */
    /* max-width: 100%; */
    outline: 0 !important;
}

.react-math-keyboard-keyboard-container {
    width: 100%;
    padding-bottom: 0.25rem;
    margin: 0px;
    color: #020617;
    background-color: #fff;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: -300px;
    left: 0px;
    z-index: 1310;
    /* transition: bottom 300ms ease-in-out; */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    /* transition: bottom 300ms cubic-bezier(0.4,0,0.2,1); */
}

.react-math-keyboard-keyboard-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.react-math-keyboard-numeric-layout {
    width: 100%;
    max-width: 480px;
    padding: 0.25rem;
    padding-top: 0.5rem;
    display: grid;
    column-gap: 0.75rem;
    /* grid-template-columns: 2fr 3fr 1fr; */
    grid-template-columns: 3fr 1fr;
}
@media (min-width: 640px) {
    .react-math-keyboard-numeric-layout {
        column-gap: 2.5rem;
    }
}
.react-math-keyboard-alphabet-layout {
    width: 100%;
    max-width: 768px;
    padding: 0.25rem;
    padding-top: 0.55rem;
    row-gap: 0.25rem;
    display: flex;
    flex-direction: column;
}

.react-math-keyboard-layout-grid {
    display: grid;
    column-gap: 0.25rem;
    row-gap: 0.25rem;
    justify-content: stretch;
}
@media (min-width: 640px) {
    .react-math-keyboard-layout-grid {
        column-gap: 0.5rem;
    }
}

.react-math-keyboard-select-container {
    border-radius: 0.25rem;
    /* padding-right: 0.25rem; */
    margin-bottom: 0.25rem;
    display: flex;
    background-color: #f8fafc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    position: relative;
}
.react-math-keyboard-select-container:focus-visible {
    outline: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.react-math-keyboard-select {
    border: transparent;
    border-radius: 0.25rem;
    background-color: transparent;
    appearance: none;
    color: #020617;
    -webkit-appearance: none;
    /* border-right-width: 16px; */
    padding-left: 0.5rem;
    /* padding-right: 0.25rem; */
    padding-right: 1.75rem;
    font-size: 1rem;
}
.react-math-keyboard-select:focus-visible {
    outline: none;
}

.react-math-keyboard-select-arrow {
    position: absolute;
    transform: rotate(90deg);
    top: 22px;
    right: 8px;
    pointer-events: none !important;
}

.react-math-keyboard-toolbar-container {
    background-color: #94a3b8;
    padding-top: 0.5rem;
    /* align-items: center; */
    width: 100%;
}
.react-math-keyboard-toolbar {
    display: flex;
    max-width: 768px;
    column-gap: 0.5rem;
    justify-content: space-between;
    padding-right: 0.25rem;
    margin: auto;
}
@media (min-width: 768px) {
    .react-math-keyboard-toolbar {
        min-width: 768px;
    }
}
.react-math-keyboard-divider {
    border: transparent;
    border-top: 2px solid #64748b;
    margin-top: 0.25rem;
    margin-bottom: 0px;
}

.react-math-keyboard-toolbar-keys-container {
    display: flex;
    column-gap: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-bottom: 0.25rem;
}

.react-math-keyboard-key {
    border: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    color: #333333;
    height: 3rem;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    background-color: #f8fafc;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.react-math-keyboard-key:focus-visible {
    outline: transparent;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.react-math-keyboard-key-utility {
    background-color: #fcf6d7;
}

@media not screen and (hover: none) and (pointer: coarse) {
    .react-math-keyboard-key:hover {
        background-color: #ececec;
    }
    .react-math-keyboard-key-utility:hover {
        background-color: #fcf699;
    }
}

.mq-math-mode {
    font-size: inherit;
    font-family: inherit;
}

.mq-math-mode * {
    font-family: Epilogue !important;
    font-style: inherit !important;
    font-weight: inherit !important;
}

.mq-math-mode .mq-editable-field {
    font-family: inherit !important;
}

.mq-math-mode .mq-font {
    font-family: inherit !important;
}

.mq-math-mode .mq-numerator {
    padding: 0px !important;
    padding-left: 1px !important;
}

.katex,
.katex * {
    font-family: Epilogue !important;
    font-weight: 400;
    font-size: 16px;
}

.renderer-table {
    height: auto; /* or height: fit-content; based on specific need */
    /* width: 100%; */
    table-layout: auto;
    /* border: 1px solid #d1d5db; */
    border-collapse: separate;
    border: none;
}

.renderer-table-cell-wrapper {
    background: #fff;
}

.renderer-table-cell {
    display: inline-flex;
    min-width: 64px;
    max-width: 280px;
    min-height: 64px;
    padding: 4px;
    align-items: center;
    align-content: center;
    gap: 0px 4px;
    flex-wrap: wrap;
    /* background:#94a3b8; */
    inline-size: max-content;
}

.renderer-table-cell .renderer-table-cell-content-text {
    color: #000;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    max-width: 100%; /* Makes the text's max-width flexible to the container's width */
    inline-size: auto;
    overflow-wrap: break-word;
}

.renderer-table-text-wrapper {
    display: flex;
    min-width: 20px;
    max-width: 100%;
    padding: 0px 4px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
}

.renderer-table-latex-wrapper {
    display: flex;
    max-width: 100%;
    min-height: 64px;
    padding: 11px 4px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
}

.renderer-table-input-wrapper {
    display: flex;
    max-width: 300px;
    /* padding: 8px 4px; */
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
}

.renderer-table-dropdown-wrapper {
    display: flex;
    min-width: 56px;
    max-width: 300px;
    min-height: 56px;
    padding: 4px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
    background: #fff;
}

.renderer-table-dropdown-item {
    display: flex;
    /* width: 84px; */
    min-width: 56px;
    max-width: 300px;
    min-height: 56px;
    padding: 4px 8px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
    border-radius: 2px;
    border: 0.5px solid #4f4f4f;
    background: #fcfbfb;
    cursor: pointer;
}

.renderer-table-dropdown-text {
    max-width: 280px;
    color: #828282;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 150%; /* 24px */
    inline-size: auto;
    overflow-wrap: break-word;
}

.renderer-table-dropdown-text-selected {
    color: #000;
    font-weight: 400;
}

.renderer-table-image-wrapper {
    display: flex;
    min-width: 64px;
    max-width: 260px;
    padding: 8px 4px;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex-wrap: wrap;
    pointer-events: none;
}

.renderer-table-image-item {
    display: flex;
    min-width: 48px;
    max-width: 300px;
    min-height: 48px;
    padding: 4px 8px;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex-wrap: wrap;
}

.renderer-table-drag-drop-area-wrapper {
    display: flex;
    width: fit-content;
    height: fit-content;
    min-width: 64px;
    max-width: 300px;
    min-height: 56px;
    padding: 8px 4px;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    border-radius: 4px;
    border: 0.5px solid #e0e0e0;
    background: #fcfbfb;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.renderer-table-dropped-item-wrapper {
    display: flex;
    min-width: 56px;
    min-height: 56px;
    padding: 4px;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px 10px;
    flex-wrap: wrap;
    border-radius: 4px;
    border: 0.5px solid #4f4f4f;
    background-color: lightgray;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1) inset;
    cursor: pointer;
    background-size: 56px;
}

.renderer-table-dropped-item-text {
    color: #000;
    text-align: center;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.renderer-table-dropped-item-wrapper > img {
    width: 48px;
}

.renderer-dnd-kit-drag-overlay-wrapper-with-element {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.surfer_canvas_container {
    width: min(100vw, 480px);
    height: calc(100dvh - 16px - 56px);
    /* min-height: calc(100vh - 16px - 56px) !important; */
    box-sizing: border-box;
}

.surfer_canvas {
    width: calc(min(100vw, 480px) - 32px);
    height: calc(100dvh - 16px - 56px - 50px);
    /* min-height: calc(100vh - 16px - 56px - 50px) !important; */
    box-sizing: border-box;
}

/* help-avatar-overlay Effect */
.help-avatar-overlay-container {
    --help-avatar-overlay-color: #3652d9;
    --help-avatar-overlay-width: 8px;
    --help-avatar-overlay-border-radius: 5px;

    position: relative;
    display: inline-block;
    width: fit-content;
    height: fit-content;
    margin-top: 4px;
    z-index: 100;
}

.help-avatar-overlay {
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
    border-radius: var(--help-avatar-overlay-border-radius);
}

.help-avatar-overlay-content {
    position: relative;
    z-index: 1;
    width: fit-content;
    height: fit-content;
    display: grid;
}

.help-avatar-overlay-avatar-icon {
    position: absolute;
    right: -10px;
    bottom: -10px;
    z-index: 100;
}
.help-avatar-overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    animation: help-avatar-overlay 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes help-avatar-overlay {
    0% {
        box-shadow: 0 0 0 0 var(--help-avatar-overlay-color);
    }
    70% {
        box-shadow: 0 0 0 var(--help-avatar-overlay-width) transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

.modal-help {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 100;
    padding: 20px;
}

.bouncy-modal {
    transition:
        transform 300ms ease-out,
        opacity 300ms ease-out;
    opacity: 0;
}

.sliding-panel-container:has(.slider-wrapper) {
    z-index: 19 !important;
}

.slider-wrapper {
    height: 210px !important;
    background-color: white;
    width: 100vw;
    max-width: 480px;
    /* center this */
    margin: auto;
    border: 1px solid #e0e0e0;
}

.slider-content {
    z-index: 10;
    margin-top: 56px;
    display: grid;
    place-items: center;
    height: 154px !important;
}

.slider-top-layer {
    overflow-x: auto;
    max-width: 440px;
    width: calc(100vw - 20px);
    margin: 10px;
}

.text-bottom-sheet-wrapper {
    height: 100vh !important;
    background-color: white;
    width: 100vw;
    max-width: 480px;
    margin: auto;
    border: 1px solid #e0e0e0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.text-bottom-sheet-content {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.text-bottom-sheet-top-layer {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.text-content {
    margin-top: 40px;
    text-align: center;
    padding: 20px;
}

.help-list {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: fit-content;
}

.help-item {
    padding: 0px 16px;
    border-radius: 8px;
    display: grid;
    grid-template-rows: 64px 32px 14px;
    place-items: center;
    gap: 5px;
    transition: all 300ms ease-in-out;
    width: 96px;
    height: 122px;
    position: relative;
    overflow: hidden;
    /* Add any additional styling for individual help items */
}

.help-item-used-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("https://sets-gamify-assets.s3.ap-south-1.amazonaws.com/dev/home-explore/document/1734118765701.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.5);
    background-size: 60%;
}

.help-item-image {
    width: 64px;
    height: 64px;
    object-fit: cover;
}

.help-item-text {
    font-weight: 501;
    font-size: 13px;
    line-height: 120%;
    color: #333333;
    margin-bottom: 0px;
    text-align: center;
}

.help-item-star-cost {
    font-size: 12px;
    line-height: auto;
    font-weight: 400;
    color: #4f4f4f;
    margin-bottom: -2px;
}

.help-item-trophy-mark {
    inset: unset !important;
    top: 0px !important;
    right: 0px !important;
}
